import { Box, Container, Flex, Image } from "@chakra-ui/react";
import React from "react";
import logo from "images/logo.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <Box
      borderBottomStyle="solid"
      borderBottomWidth={2}
      borderBottomColor="gray.100"
    >
      <Container maxW="7xl">
        <Flex py={6}>
          <Link to="/">
            <Image h={8} alt="rsVIP" src={logo} />
          </Link>
        </Flex>
      </Container>
    </Box>
  );
};

export default Navbar;
