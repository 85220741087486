import { ChakraProvider } from "@chakra-ui/react";
import Landing from "pages/Landing";
import NotFound from "pages/NotFound";
import WebsiteBuilder, {
  loader as projectPageLoader,
} from "pages/WebsiteBuilder";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import theme from "theme";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/:projectSlug",
    element: <WebsiteBuilder />,
    errorElement: <NotFound />,
    loader: projectPageLoader,
  },
]);

export const App = () => (
  <ChakraProvider theme={theme}>
    <RouterProvider router={router} />
  </ChakraProvider>
);
