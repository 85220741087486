import { Heading, Image, VStack } from "@chakra-ui/react";
import Section from "components/website/Section";
import ProfessionalImage from "images/benefits/professional.png";
import logo from "images/logo.png";
import React from "react";
import { useTranslation } from "react-i18next";

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <Section id="hero" mb={[0, -12, -16, -20, -28]} textAlign="center">
      <VStack gap={8} alignItems="stretch" maxW="2xl" mx="auto">
        <Image w={[160, 200, 240]} alt="rsVIP" src={logo} alignSelf="center" />

        <Heading
          as="h1"
          fontSize={["4xl", "5xl", "6xl"]}
          lineHeight={1}
          fontWeight="extrabold"
          letterSpacing="tight"
          color="gray.900"
          textAlign="center"
        >
          {t("landing-hero-section-title")}
        </Heading>

        <Image
          src={ProfessionalImage}
          alt="Professional"
          loading="eager"
          filter="drop-shadow(0px 5px 4px rgba(80,70,229,.3))"
        />
      </VStack>
    </Section>
  );
};

export default HeroSection;
