import { Heading, Image, Link, Text, VStack } from "@chakra-ui/react";
import Footer from "components/website/Footer";
import Navbar from "components/website/Navbar";
import Section from "components/website/Section";
import Seo from "components/website/Seo";
import NotFoundImage from "images/notfound.jpg";
import React from "react";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <Seo />
      <Navbar />

      <Section id="not-found" h="100vh" maxW="4xl" pt={0}>
        <Image src={NotFoundImage} alt="Dancing people" loading="eager" />
        <VStack textAlign="center" gap={4}>
          <Heading as="h1" size="3xl">
            {t("not-found-title")}
          </Heading>
          <Text fontSize="xl" color="gray.500">
            {t("not-found-description")}
          </Text>
          <Link
            href="/"
            px="6"
            py="3"
            whiteSpace="nowrap"
            fontWeight="medium"
            color="white"
            bgGradient="linear(to-br, pink.600, indigo.600)"
            borderRadius="md"
            _hover={{
              bgGradient: "linear(to-br, pink.700, indigo.700)",
            }}
          >
            {t("not-found-cta")}
          </Link>
        </VStack>
      </Section>

      <Footer />
    </>
  );
};

export default NotFound;
