import {
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react";
import Section from "components/website/Section";
import React from "react";
import { useTranslation } from "react-i18next";
import { GoCheckCircleFill } from "react-icons/go";

const pros = Array(4).fill(null);

const HighlightSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <Section id="highlight-title" pb={0}>
        <VStack gap={4} alignItems="stretch" maxW="2xl" mx="auto">
          <Heading size={["md", "lg"]} mb={4}>
            {t("landing-highlight-section-title-1")}
          </Heading>

          <List spacing={3}>
            {pros.map((_, index) => (
              <ListItem key={index} fontSize={["lg", "xl"]}>
                <ListIcon as={GoCheckCircleFill} color="indigo.600" />
                {t(`landing-highlight-section-pros-${index + 1}`)}
              </ListItem>
            ))}
          </List>

          <Heading size={["md", "lg"]} mt={12} mb={4}>
            {t("landing-highlight-section-title-2")}
          </Heading>

          <Text fontSize={["lg", "xl"]} whiteSpace="pre-line">
            {t("landing-highlight-section-facts-1")}
          </Text>
        </VStack>
      </Section>
    </>
  );
};

export default HighlightSection;
