import Footer from "components/website/Footer";
import Seo from "components/website/Seo";
import React, { FC } from "react";
import { RouteProps } from "react-router-dom";
import CtaSection from "./components/CtaSection";
import HeroSection from "./components/HeroSection";
import HighlightSection from "./components/HighlightSection";

const Landing: FC<RouteProps> = () => (
  <>
    <Seo />

    <HeroSection />

    <HighlightSection />

    <CtaSection />

    <Footer />
  </>
);

export default Landing;
