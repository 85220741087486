import { Box, ChakraProps, Container } from "@chakra-ui/react";
import React, { FC } from "react";

interface SectionProps extends ChakraProps {
  id: string;
  backgroundColor?: string;
  children: React.ReactNode;
}

const Section: FC<SectionProps> = ({
  id,
  backgroundColor,
  bgGradient,
  children,
  ...containerProps
}) => {
  return (
    <Box id={id} backgroundColor={backgroundColor} bgGradient={bgGradient}>
      <Container maxW="7xl" py={[16, 20, 20, 24]} px={[4, 6, 6, 8]} {...containerProps}>
        {children}
      </Container>
    </Box>
  );
};

export default Section;
