import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  VisuallyHidden,
} from "@chakra-ui/react";
import Section from "components/website/Section";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Field, Form, Formik, FormikConfig } from "formik";
import { FieldProps } from "formik/dist/Field";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { HiCheckCircle } from "react-icons/hi2";
import { CtaFormValues } from "./types";

const initialValues: CtaFormValues = {
  email: "",
  privacy: false,
  botField: "",
};

const CtaSection = () => {
  const [isSent, setIsSent] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t } = useTranslation();

  const functions = getFunctions(undefined, "europe-west1");
  const handleContactForm = httpsCallable(functions, "contactForm");

  const handleSubmit: FormikConfig<CtaFormValues>["onSubmit"] = (
    values,
    formikHelpers
  ) => {
    if (values.botField !== "" || !values.privacy) {
      formikHelpers.setSubmitting(false);
      return;
    }

    handleContactForm(values)
      .then(() => {
        setIsSent(true);
      })
      .catch((error) => {
        alert(error.message);
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <Section id="cta-contact">
      <Box
        my={8}
        px={[4, 6, 6, 8]}
        py={24}
        bgGradient="linear(to-br, pink.600, indigo.600)"
        borderRadius="xl"
        textAlign="center"
        color="whiteAlpha.900"
      >
        <Heading size="xl">{t("landing-cta-section-title")}</Heading>

        {isSent ? (
          <Flex mt={8} flexDirection="column" alignItems="center">
            <Icon as={HiCheckCircle} boxSize={12} />
            <Text fontSize="xl">{t("landing-cta-section-sent-message")}</Text>
          </Flex>
        ) : (
          <Formik
            name="contact"
            onSubmit={handleSubmit}
            initialValues={initialValues}
          >
            {({ isSubmitting }) => (
              <Form>
                <Flex
                  flexDirection={["column", "column", "row"]}
                  flexWrap="wrap"
                  mt={8}
                  maxW="lg"
                  mx="auto"
                  gap={4}
                >
                  <Field name="botField">
                    {({ field }: FieldProps) => (
                      <VisuallyHidden>
                        <label>
                          Don’t fill this out if you’re human:
                          <input {...field} />
                        </label>
                      </VisuallyHidden>
                    )}
                  </Field>

                  <Field name="email">
                    {({ field }: FieldProps) => (
                      <Input
                        required
                        placeholder={t("home-cta-section-input-placeholder")!}
                        {...field}
                        w={["full", "auto"]}
                        flexGrow={1}
                        px={4}
                        py={3}
                        borderRadius="md"
                        bg="white"
                        color="gray.800"
                      />
                    )}
                  </Field>

                  <Field name="privacy">
                    {({ field }: FieldProps) => (
                      <Checkbox
                        required
                        isChecked={field.value}
                        {...field}
                        textAlign="justify"
                        order={[0, 0, 2]}
                      >
                        <Text fontSize="sm">
                          <Trans
                            i18nKey="home-cta-section-privacy"
                            components={[
                              <Button
                                variant="link"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onOpen();
                                }}
                                fontSize="sm"
                                colorScheme="white"
                              >
                                privacy policy
                              </Button>,
                            ]}
                          />
                        </Text>
                      </Checkbox>
                    )}
                  </Field>

                  <Button
                    type="submit"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                    w={["full", "auto"]}
                    px={8}
                    py={3}
                    fontWeight="medium"
                    borderRadius="md"
                    color="gray.800"
                    _hover={{
                      bgColor: "gray.200",
                    }}
                  >
                    {t("home-cta-section-submit-button")}
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        )}
      </Box>

      <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={4}>
            <iframe
              width="100%"
              height="500px"
              src="https://www.iubenda.com/privacy-policy/26827265"
              title="Privacy policy"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Section>
  );
};

export default CtaSection;
